import React from 'react'

const Sidebar = ({ data25, data24, data23, data22, data21, data20, child }) => {
    const handleChild = (elem) => {
        localStorage.setItem(elem.name, JSON.stringify(elem))
        localStorage.setItem('cName', elem.name)
    }
    return (
        <div className='sidebar'>
            <ul className="mt-2 p-3">
            <li className='side-li'>
                    <button onClick={ data25 } className='side-btn bg-white p-1'>2025 Report</button>
                </li>
                <li className='side-li'>
                    <button onClick={ data24 } className='side-btn bg-white p-1'>2024 Report</button>
                </li>
                <li className='side-li'>
                    <button onClick={ data23 } className='side-btn bg-white p-1'>2023 Report</button>
                </li>
                <li className='side-li'>
                    <button onClick={ data22 } className='side-btn bg-white p-1'>2022 Report</button>
                </li>
                <li className='side-li'>
                    <button onClick={ data21 } className='side-btn bg-white p-1'>2021 Report</button>
                </li>
                <li className='side-li'>
                    <button onClick={ data20 }  className='side-btn bg-white p-1'>2020 Report</button>
                </li>
            </ul>
            {child !== null && child !== undefined ?
                <div>
                    <h5 className='mb-0 pl-2 pr-2 text-bold'>Properties</h5>
                    <ul className='p-2'>
                        {child.map((elem, index) => {
                            return (
                                <li onClick={() => handleChild(elem)} className='side-li p-1' key={index}><a href='/company' target='_blank' rel="noreferrer">{elem.company}</a></li>
                            )
                        })}
                    </ul>
                </div> : null
            }
        </div>
    )
}

export default Sidebar